import React from 'react'
import Image from 'atoms/contentful/image'
import { Link, navigate } from 'gatsby'
import ClickableTags from './clickable-tags'

const BlockResource = ({
  image,
  categories,
  slug,
  title = 'title',
  tags = [],
}) => {
  const handleClick = (e) => {
    const path = `/resources/${categories.key}/${slug}`;
    // Check if this is a special case that needs redirection
    if (path.includes('What-Is-a-CAT-Tool')) {
      e.preventDefault();
      window.location.href = '/resources/101/what-is-a-cat-tool/';
      return;
    }
  };

  return (
    <>
      <Link to={`/resources/${categories.key}/${slug}`} onClick={handleClick}>
        <Image {...image} />
      </Link>

      <h5 className="o-kicker o-kicker__article u-space--half--top">
        <p className="o-kicker">{categories.name}</p>
      </h5>
      <Link to={`/resources/${categories.key}/${slug}`} onClick={handleClick}>
        <h4 className="u-color--black u-font--s">{title}</h4>
      </Link>
      {tags && tags.length > 0 && (
        <p className="u-font--s">
          <strong>Tags: </strong>
          <ClickableTags tags={tags} />
        </p>
      )}
    </>
  )
}

export default BlockResource
